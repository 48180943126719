<template>
    <div class="viewContainer" >
        <!-- 内容 -->
        <div class="topTitle">
            购买流程
        </div>

        <hr  class="hr">

        <div class="content">
            <div class="title">
                <img src="@/assets/image/services/buy_process.png" alt="">
                <div class="text">购买流程</div>
                <div class="greyLine"></div>
            </div>
            <div class="tips">当您在填写e-mail的时候，请将您的联系方法尽可能填写详细，以便我们的回复，谢谢合作！</div>
            <div class="imgContainer">
                <img class="processImg" src="@/assets/image/services/processImg.png" alt="">
                <a class="hideLink" style="left:36%; top:6%" href="https://www.wch.cn/downloads/category/27.html"></a>
                <a class="hideLink" style="left: 40%; top: 10%;" href="https://www.wch.cn/services/technical_support.html"></a>
                <a class="hideLink" style="left: 8.2%; top: 28.8%;" href="https://www.wch.cn/services/sale_support.html"></a>
                <a class="hideLink" style="left: 8.2%; top: 47.5%;" href="https://www.wch.cn/contact_us.html"></a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "buyProcess",
  components:{
  },
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        
    };
  },
  computed: {
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 768) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
  },
  activated(){
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    
    
  }
};
</script>
<style scoped lang="less">
 .viewContainer{
    width: 100%;
    text-align: left;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;

    .topTitle{
        font-size: 22px;
        color: #002c85;
        font-weight: 600;
        letter-spacing: 0;
        margin-bottom: 20px;
    }
    .hr{
        border-top: 1px solid #cbd0d4;
        margin: 25px 0px;
    }
    .content{
        margin: 40px 0px;
       .title{
            display: flex;
            align-items: center;
            .text{
                font-size: 26px;
                float: left;
                margin-left: 20px;
            }
            .greyLine{
                margin-left: 20px;
                height: 10px;
                float: left;
                border-bottom: 1px solid #cbd0d4;
                flex: 1;
            }
        }
        .tips{
            margin-top: 20px;
            padding-left: 30px;
            font-size: 14px;
            color: #AFAFAF;
            padding-right: 50px;
            margin-bottom: 40px;
        }
        .imgContainer{
            position: relative;
            .processImg{
                margin-left: 5%;
                // max-width: 100%;
                width: 40.4%;
            }
            .hideLink{
                position: absolute;
                display: inline-block;
                width: 5.5%;
                height: 3%;
                // background: #cccccc85;
            }
        }
        
    }
 }

</style>